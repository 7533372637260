<template>
  <AdminReportingForm
    title="Cancelled Reservations"
    description="The Cancelled Reservations Report is at the park level and provides
      details for reservations that have been cancelled matching the date range
      specified. This report can be run for any 365 day date range."
    file="CancelledReservations$"
    :reportStatus="reportStatus"
    :submitting="submitting"
    :allowGlobal="true"
    @getReport="getReport"
  />
</template>

<script>
import AdminReportingForm from "@/components/admin/AdminReportingForm.vue";
import AdminReportingService from "@/services/admin/AdminReportingService.js";
import moment from "moment";

export default {
  name: "CancelledReservationsReport",
  title: "Admin - Cancelled Reservations Report",
  data() {
    return {
      reportStatus: "",
      submitting: false
    };
  },
  components: {
    AdminReportingForm
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    getReport(reportData) {
      this.reportStatus = "";
      this.submitting = true;
      const date = moment();
      reportData.dateRun = date.format("MM/DD/YYYY hh:mm:ss A");

      const service = new AdminReportingService(this.tenantId);
      service
        .getCancelledReservationsReport(reportData)
        .then(response => {
          if (response.deliveryMethod == "Download") {
            const filename = reportData.filename.replace(
              "$",
              date.format("MM_DD_YYYY_hh_mm_ss_A")
            );
            service.forceFileDownload(response, filename);
          } else {
            this.reportStatus = "Report sent!";
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
};
</script>
